import React from 'react';
import {
  Datagrid,
  TextField,
  NumberField,
  ListContextProvider,
  Filter,
  SelectInput,
  ExportButton,
  usePermissions,
  useListController,
} from 'react-admin';

export namespace Report {
  const ReportFilter = (props: any) => (
    <Filter {...props}>
      <SelectInput
        source="time"
        choices={[
          { id: 'date', name: 'date' },
          { id: 'month', name: 'month' },
        ]}
        initialValue={'date'}
        allowEmpty={false}
        alwaysOn
      />
      {/* <SelectInput
        source="group"
        label="Group by"
        choices={[
          { id: 'package', name: 'Package' },
          { id: 'subscription', name: 'Subscription' },
          { id: 'user', name: 'Played Users' },
        ]}
        alwaysOn
      /> */}
    </Filter>
  );

  export const List: React.FC = (props: any) => {
    const { permissions = [] } = usePermissions();
    const value = useListController(props);
    const { time = 'date' } = value.filterValues;

    if (!permissions.includes('admin'))
      return <div>Access denied. You do not have sufficient privileges to view Reports.</div>;

    return (
      <ListContextProvider value={value}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ReportFilter />
          <ExportButton />
        </div>
        <Datagrid isRowSelectable={() => false}>
          <TextField source={time} />
          <NumberField source="amount" />
        </Datagrid>
      </ListContextProvider>
    );
  };
}
