import { downloadCSV } from 'react-admin';
import { CheckBoxRounded, CheckBoxOutlineBlankRounded } from '@material-ui/icons';
import jsonExport from 'jsonexport/dist';

export namespace Util {
  export const booleanRender = (value: boolean) =>
    value ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />;

  export const exportCSV = (records: any[], filename: string) =>
    jsonExport(records, {}, (err: any, csv: any) => downloadCSV(csv, filename));
}
