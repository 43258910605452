import { IELOSchema, BINARY } from '@elofun/elo-protobuf/types';

export const SERVICE_HOST = /localhost/i.test(window.location.hostname)
  ? 'ws://localhost:3000'
  : 'wss://' + window.location.hostname.replace(/^cms-/i, '');

export const IRequestSchema: IELOSchema = {
  reqIndex: BINARY,
  name: BINARY,
  api: BINARY,
  payload: BINARY,
};

export const IResponseSchema: IELOSchema = {
  reqIndex: BINARY,
  data: BINARY,
};
