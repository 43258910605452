import moment from 'moment-timezone';
import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  ImageField,
  DateField,
  TabbedShowLayout,
  List as ElementList,
  Show as ElementShow,
  Tab,
  Filter,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceManyField,
  Pagination,
  ExportButton,
  usePermissions,
} from 'react-admin';
import { Util } from '../common/utils';
import { TYPES, CHANNELS } from '../common/defines';

export namespace User {
  const UserFilter = (props: any) => (
    <Filter {...props}>
      <TextInput source="msisdn" alwaysOn />
    </Filter>
  );

  export const List: React.FC = (props: any) => {
    return (
      <ElementList {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <FunctionField
            label="EloPlay VIP"
            render={(record: any) =>
              Util.booleanRender(
                record.subscriptions?.some(({ packageId }: any) => packageId == '104')
              )
            }
          />
        </Datagrid>
      </ElementList>
    );
  };

  export const Show: React.FC = (props: any) => {
    const { permissions = [] } = usePermissions();
    return (
      <ElementShow {...props}>
        <TabbedShowLayout>
          <Tab label="Info">
            <ImageField source="avatar" label="" />
            <TextField source="id" />
            <FunctionField
              label="EloPlay VIP"
              render={(record: any) =>
                Util.booleanRender(
                  record.subscriptions?.some(({ packageId }: any) => packageId == '104')
                )
              }
            />
          </Tab>
          <Tab label="Subscription">
            <ReferenceManyField
              label=""
              reference="Subscription"
              target="id"
              filter={{ userId: props.id }}
              pagination={<Pagination />}
            >
              <>
                {permissions.includes('users.export') && (
                  <ExportButton
                    exporter={(records) => Util.exportCSV(records, `subscription-${props.id}`)}
                  />
                )}
                {/* <TransactionFilter /> */}
                <Datagrid>
                  <TextField source="packageId" />
                  <TextField source="createdAt" />
                  <TextField source="expiredAt" />
                  <TextField source="transactionId" />
                  <FunctionField
                    label="New"
                    sortable={false}
                    render={(record: any) => Util.booleanRender(record.subNew)}
                  />
                </Datagrid>
              </>
            </ReferenceManyField>
          </Tab>
          {/* <Tab label="User Activity">
            <ReferenceManyField
              label=""
              reference="UserActivity"
              target="id"
              filter={{ userId: props.id }}
              pagination={<Pagination />}
            >
              <>
                {permissions.includes('users.export') && (
                  <ExportButton
                    exporter={(records) => Util.exportCSV(records, `user-activity-${props.id}`)}
                  />
                )}
                <Datagrid>
                  <TextField source="id" />
                  <TextField source="gameId" />
                  <TextField source="packageId" />
                  <TextField source="source" />
                  <TextField source="playedAt" />
                </Datagrid>
              </>
            </ReferenceManyField>
          </Tab> */}
        </TabbedShowLayout>
      </ElementShow>
    );
  };

  const TransactionFilter = (props: any) => (
    <Filter {...props}>
      <DateInput source="createdAt" label="Date" alwaysOn />
      <SelectInput source="type" choices={TYPES} alwaysOn />
      <SelectInput source="channel" choices={CHANNELS} alwaysOn />
    </Filter>
  );

  const BalanceFilter = (props: any) => (
    <Filter {...props}>
      <DateInput source="createdAt" label="Date" alwaysOn />
      <TextInput source="search" label="Search" alwaysOn />
    </Filter>
  );
}
