import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: '100%',
    height: '88vh',
    border: 'none',
  },
}));

export const HomePage = () => {
  const { dashboard } = useStyles();
  return (
    <Card>
      <CardContent>
        <iframe
          className={dashboard}
          src="https://a2451b3f-9cdd-4fa4-8e30-91a15bcd49ad.elofun.net/d/MYTeKdG7z/cms-fpt?orgId=1&refresh=10s&kiosk"
        />
      </CardContent>
    </Card>
  );
};
