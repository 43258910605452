import { Admin, Resource } from 'react-admin';
import { Person, SportsEsports, CardMembership, AttachMoney } from '@material-ui/icons';
import { Provider } from './common/provider';
import { HomePage } from './HomePage';
import { User } from './resources/User';
import { Games } from './resources/Games';
import { Subscription } from './resources/Subscription';
import { Report } from './resources/Report';
import './App.css';

const App = () => (
  <Admin
    dashboard={HomePage}
    dataProvider={Provider.dataProvider}
    authProvider={Provider.authProvider}
  >
    <Resource name="User" icon={Person} list={User.List} show={User.Show} />
    <Resource
      name="Games"
      icon={SportsEsports}
      list={Games.List}
      // create={Game.Create}
      edit={Games.Edit}
    />
    <Resource name="Subscription" icon={CardMembership} list={Subscription.List} />
    <Resource name="Report" icon={AttachMoney} list={Report.List} />
    <Resource name="UserActivity" />
    <Resource name="Package" />
    <Resource name="Category" />
  </Admin>
);

export default App;
