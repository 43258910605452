import * as uuid from 'uuid';
import Cookies from 'js-cookie';
import { ACL } from './defines';
import { Client } from './client';

export namespace Provider {
  export const dataProvider: any = [
    'getList',
    'getOne',
    'getMany',
    'getManyReference',
    'create',
    'update',
    'updateMany',
    'delete',
    'deleteMany',
  ].reduce(
    (providers, api) => ({
      ...providers,
      [api]: (resource: any, params: any) =>
        Client.request({ name: resource, api, payload: params }),
    }),
    {}
  );

  export const authProvider: any = {
    login: (payload: any) =>
      Client.request({ name: 'Auth', api: 'login', payload }).then((response) => {
        if (response.status == 401) {
          throw response;
        }
        const { username, role } = response;
        Cookies.set('auth', { username, role, id: uuid.v4() }, { expires: 1 });
      }),
    checkError: (error: any) => {
      console.log('checkError', error);
      return Promise.resolve();
    },
    checkAuth: (params: any) => {
      const { id } = Cookies.getJSON('auth') || {};
      return uuid.validate(id)
        ? Promise.resolve()
        : Promise.reject({ redirectTo: '/login', message: 'Not authorize' });
    },
    logout: () => {
      Cookies.remove('auth');
      return Promise.resolve('/login');
    },
    getIdentity: () => {
      const { username } = Cookies.getJSON('auth') || {};
      return Promise.resolve({ fullName: username });
    },
    getPermissions: (params: any) => {
      const { role } = Cookies.getJSON('auth') || {};
      return Promise.resolve(ACL[role]);
    },
  };
}
