import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  List as ElementList,
  Create as ElementCreate,
  Edit as ElementEdit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useRedirect,
} from 'react-admin';
import { Util } from '../common/utils';

export namespace Games {
  export const List: React.FC = (props: any) => {
    return (
      <ElementList {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="hash" />
          <TextField source="priority" />
          <TextField source="totalPlayer" />
          <TextField source="controllerPrefix" />
          <ReferenceArrayField source="packages" reference="Package">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField source="categories" reference="Category">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <FunctionField
            label="Active"
            sortable={false}
            render={(record: any) => Util.booleanRender(record.active)}
          />
        </Datagrid>
      </ElementList>
    );
  };

  const Form = (props: any) => (
    <SimpleForm {...props}>
      <TextField source="id" />
      <TextField source="hash" />
        <TextInput source="name" />
        <NumberInput source="priority" />
        <NumberInput source="totalPlayer" />
        <TextInput source="controllerPrefix" />
        <ReferenceArrayInput source="packages" reference="Package">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="categories" reference="Category">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="active" initialValue={props.record.active} />
      </SimpleForm>
    );

  export const Create = ({ hasList, hasShow, hasCreate, hasEdit, ...props }: any) => {
    const redirect = useRedirect();
    return (
      <ElementCreate {...props} onSuccess={(props: any) => redirect('/Game')}>
        <Form {...props} />
      </ElementCreate>
    );
  };

  export const Edit: React.FC = ({ hasList, hasShow, hasCreate, hasEdit, ...props }: any) => {
    return (
      <ElementEdit {...props}>
        <Form {...props} />
      </ElementEdit>
    );
  };
}
