import { EventEmitter } from 'eventemitter3';
import { ELOPackage } from '@elofun/elo-protobuf/package';
import { SERVICE_HOST, IRequestSchema, IResponseSchema } from './conf';

export namespace Client {
  const emitter = new EventEmitter();
  let socket: WebSocket;

  export const connect = (): Promise<WebSocket> =>
    new Promise((resolve) => {
      if (socket?.readyState == WebSocket.OPEN) return resolve(socket);
      const conn = new WebSocket(SERVICE_HOST);
      conn.onopen = (event: any) => resolve((socket = conn));
      conn.onmessage = (ev: MessageEvent) =>
        ev.data.arrayBuffer().then((buffer: ArrayBuffer) => {
          const { reqIndex, data } = ELOPackage.unpack(IResponseSchema, Buffer.from(buffer) as any);
          emitter.emit('RESPONSE' + reqIndex.toString(), JSON.parse(data.toString()));
        });
      conn.onclose = (event: any) => null;
      conn.onerror = (event: any) => conn.close();
    });

  export const request = (params: { name: string; api: string; payload?: any }) => {
    const { name, api, payload = {} } = params;
    return new Promise<any>((resolve, reject) => {
      const reqIndex = String(Math.random()).slice(-5);
      connect().then((socket) => {
        socket.send(
          ELOPackage.pack(IRequestSchema, {
            reqIndex: Buffer.from(reqIndex),
            name: Buffer.from(name),
            api: Buffer.from(api),
            payload: Buffer.from(JSON.stringify(payload)),
          } as any)
        );
      });
      emitter.once('RESPONSE' + reqIndex, resolve);
    });
  };
}
