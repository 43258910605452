import React from 'react';
import {
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  List as ElementList,
  Create as ElementCreate,
  Edit as ElementEdit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useRedirect,
} from 'react-admin';
import { Util } from '../common/utils';

export namespace Subscription {
  export const List: React.FC = (props: any) => {
    return (
      <ElementList {...props} bulkActionButtons={false}>
        <Datagrid rowClick={undefined}>
          <TextField source="userId" />
          <TextField source="packageId" />
          <NumberField source="price" />
          <TextField source="createdAt" />
          <TextField source="expiredAt" />
          <TextField source="transactionId" />
          <FunctionField
            label="New"
            sortable={false}
            render={(record: any) => Util.booleanRender(record.subNew)}
          />
        </Datagrid>
      </ElementList>
    );
  };
}
