export const ACL: { [key: string]: string[] } = {
  dev: ['users.show', 'users.export', 'leaderboard.show', 'leaderboard.approve', 'admin', 'dev'],
  admin: ['users.show', 'users.export', 'leaderboard.show', 'leaderboard.approve', 'admin'],
  CSKH: ['users.show', 'leaderboard.show'],
};

export const TYPES = [
  { id: 'REGISTER', name: 'REGISTER' },
  { id: 'CHARGE', name: 'CHARGE' },
  { id: 'MONFEE', name: 'MONFEE' },
];

export const CHANNELS = [
  { id: 'ONLINE', name: 'ONLINE' },
  { id: 'SMS', name: 'SMS' },
  { id: 'USSD', name: 'USSD' },
  { id: 'RENEW', name: 'RENEW' },
];
